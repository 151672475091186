import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { catchError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeadService {

  constructor(
    private titleService: Title, 
    private meta: Meta,
    @Inject(PLATFORM_ID) private platformId: Object

  ) { }

  setTitle(newTitle:string=""){
    this.titleService.setTitle(newTitle + ' | Index Fund Advisors, Inc.');
    this.meta.updateTag({ property: 'og:title', content: newTitle });    
    this.meta.updateTag({ name: 'twitter:title', content: newTitle });
  }
  setDescription(newDescription:string=""){
    this.meta.updateTag({ name: 'description', content: newDescription });
    this.meta.updateTag({ property: 'og:description', content: newDescription });
    this.meta.updateTag({ name: 'twitter:description', content: newDescription });
  }
  setKeywords(newKeywords:string=""){
    this.meta.updateTag({ name: 'keywords', content: newKeywords });
  }

  setImageOG(imageUrl:string=""){
    this.meta.updateTag({ property: 'og:image', content: imageUrl });
    this.meta.updateTag({ name: 'twitter:image', content: imageUrl });
    this.meta.updateTag({ name: 'twitter:card', content: 'summary_large_image' });   
    this.meta.updateTag({ name: 'twitter:site', content: '@ifadotcom' }); 
  }

  setTeaser(teaser:string=""){
    this.meta.updateTag({ name: 'twitter:image:alt', content: teaser });
  }

  setType(pageType:string=""){
    this.meta.updateTag({ property: 'og:type', content: pageType});
  }
  setMetaProperty(property:string="", content:string=""){
    this.meta.updateTag({ property: property, content: content});
  }

  removeKeywords(newKeywords:string=""){
    this.meta.removeTag( 'keywords');
  }
  removeDescription(){
    this.removeMetaName("description");
    this.removeMetaProperty("og:description");
    this.removeMetaProperty("twitter:description");
  }
  removeType(){
    this.removeMetaProperty("og:type");   
  }
  removeImageOG(){
    try{
      this.meta.removeTag("property='og:image'");
      this.meta.removeTag("name='twitter:image'");
      this.meta.removeTag("name='twitter:card'");
      this.meta.removeTag("name='twitter:site'");
    } catch (error) {
      console.warn(error);
    }    
  }
  removeMetaProperty(property:string=""){  
     if (isPlatformBrowser(this.platformId)) { 
      //safe now to use document (ssr) 
      try{
        const metaProperty = document.querySelector(`meta[property="${property}"]`) as HTMLElement;  
        if (metaProperty) {  
          metaProperty.parentNode?.removeChild(metaProperty);  
        }
      } catch (error) {
        console.warn(error);
      }    
    }
  }
  removeMetaName(name:string=""){  
    if (isPlatformBrowser(this.platformId)) {
      //safe now to use document (ssr)  
      try{
        const metaName = document.querySelector(`meta[name="${name}"]`) as HTMLElement;  
        if (metaName) {  
          metaName.parentNode?.removeChild(metaName);  
        }
      } catch (error) {
        console.warn(error);
      }    
    }
  }

  setMetaSocial(title:string,description:string,keywords:string,imageUrl:string,teaser:string,type:string,url:string){

      this.titleService.setTitle(title + ' | Index Fund Advisors, Inc.');
      this.meta.updateTag({ name: 'description', content: description });
      this.meta.updateTag({ name: 'keywords', content: keywords });

      this.meta.updateTag({ name: 'twitter:card', content: 'summary_large_image' });   
      this.meta.updateTag({ name: 'twitter:site', content: '@ifadotcom' });    
      this.meta.updateTag({ name: 'twitter:title', content: title });
      this.meta.updateTag({ name: 'twitter:description', content: description });        
      this.meta.updateTag({ name: 'twitter:image', content: imageUrl });
      this.meta.updateTag({ name: 'twitter:image:alt', content: teaser });

      this.meta.updateTag({ property: 'og:url', content: url});      
      this.meta.updateTag({ property: 'og:title', content: title });    
      this.meta.updateTag({ property: 'og:description', content: description });
      this.meta.updateTag({ property: 'og:image', content: imageUrl });
      this.meta.updateTag({ property: 'og:type', content: type});       

  }
  removeMetaSocial(){

    this.meta.removeTag("name='twitter:card'");
    this.meta.removeTag("name='twitter:site'");
    this.meta.removeTag("name='twitter:title'");
    this.meta.removeTag("name='twitter:description'");
    this.meta.removeTag("name='twitter:image'");
    this.meta.removeTag("name='twitter:image:alt'");

    this.meta.removeTag("property='og:url'");
    this.meta.removeTag("property='og:title'");
    this.meta.removeTag("property='og:description'");
    this.meta.removeTag("property='og:image'");
    this.meta.removeTag("property='og:type'");

    this.titleService.setTitle('Index Fund Advisors, Inc.');
  }

}
